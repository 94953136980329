<template>
  <div>

    <h3 class="text-center">Completa los campos para crear una salida de productos</h3>

    <validation-observer ref="createOrderValidation">
    
      <b-form
        class="auth-forgot-password-form mt-2"
        @submit.prevent="createOrder"
      >

        <b-row>

          <b-col
            cols="12"
            md="6"
          >
            
            <b-form-group
              label="Destino"
              label-for="product_type_id"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Destino"
                  rules="required"
                >

                <b-form-select
                  id="product_type_id"
                  v-model="destinationId"
                  :options="destinations"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              
            </b-form-group>

          </b-col>

          <b-col
            md="6"
          >
            
            <b-form-group
              label="Selecciona conductor"
              label-for="deliverer_id"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Selecciona conductor"
                >

                <b-form-select
                  id="deliverer_id"
                  v-model="delivererId"
                  :options="deliverers"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              
            </b-form-group>

          </b-col>

          <b-col
            md="12"
          >
            <hr>
          </b-col>

          <b-col
            md="12"
          >
            <h4 class="mb-2">Ingresa la cantidad correspondiente al producto que se va a despachar</h4>
          </b-col>

        </b-row>

        <b-row 
        v-for="(product, index) in productsPop"
        :key="index"
        >

          <b-col
            cols="12"
            md="2"
            class="text-right"
          >
            
            <p>{{ product.name }}</p>

          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Cantidad"
              label-for="quantity_product"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Cantidad"
                >

                <b-form-input
                  type="number"
                  id="quantity_product"
                  v-model="product.qty_order"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>
        </b-row>

        <b-spinner
          v-show="loading"
          variant="primary"
          label="Loading..."
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
           />

        <b-button
          v-show="!loading"
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          Crear pedido
        </b-button>
        <b-button
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="$router.push({name: 'order-list'})"
        >
          Cancelar
        </b-button>

      </b-form>
    </validation-observer>


  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormSelect, BSpinner,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { codeMask } from '@/views/forms/form-element/form-input-mask/code'
import { required, email } from '@validations'
import {constants} from "@/helpers/constants"

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormSelect,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      host: constants.hostUrl,
      settingData: {},
      quantity_products: null,
      product_type_id: null,
      accessToken: localStorage.getItem('accessToken'),
      productTypeOptions: [
        {
          text: 'Selecciona una opción',
          value: null,
          disabled: true,
        },
        {
          text: 'BOLD NEO',
          value: '1',
        },
        {
          text: 'BOLD PLUS',
          value: '2',
        },
        {
          text: 'BOLD SMART',
          value: '3',
        },
        {
          text: 'BOLD PRO',
          value: '4',
        },
      ],
      destinations: [
        {
          text: 'Selecciona una opción',
          value: null,
          disabled: true,
        }
      ],
      destinationId: null,
      orderRequest: '',
      loading: false,
      delivererId: null,
      deliverers: [
        {
          text: 'Selecciona una opción',
          value: null,
        }
      ],
      productsPop: null,
    }
  },
  setup(props) {
    const countryOptions = [
      { label: 'Colombia', value: 1 },
    ]

    const serializedOptions = [
      { label: 'Texto', value: 0 },
      { label: 'JSON', value: 1 },
    ]

    return {
      countryOptions,
      serializedOptions,
    }
  },
  created() {
    this.getDestinations()
    this.getDeliverers()
    this.getKits()
  },
  methods: {
    getDeliverers(){
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/deliverers', { headers }).then(response => {
        console.log('deliverers: ', response)

        let deliverers = response.data.data.deliverers

        deliverers.forEach(deliverer => {
          this.deliverers.push({
            text: deliverer.name + ' - ' + deliverer.plate,
            value: deliverer.id,
          },)
        })
       
      }).catch( err => {
        console.log(err)
      })

    },
    getDestinations(){
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/destinations', { headers }).then(response => {
        console.log('destinations: ', response)

        let destinations = response.data.data.destinations

        destinations.forEach(destination => {
          this.destinations.push({
            text: destination.name + ' (' + destination.address + ')',
            value: destination.id,
          },)
        })
       
      }).catch( err => {
        console.log(err)
      })

    },
    getKits(){
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/get-kits', { headers }).then(response => {
        console.log('get-kits: ', response)

        this.productsPop = response.data.data.kits
       
      }).catch( err => {
        console.log(err)
      })

    },
    createOrder() {

      console.log(this.productsPop)

      event.preventDefault()
      this.$refs.createOrderValidation.validate().then(success => {
        if (success) {

          this.loading = true

          let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          }
          axios.post(this.host + '/api/front-web/pop-orders', {
            products_pop: this.productsPop,
            order_status_id: 1,
            destination_id: this.destinationId,
            deliverer_id: this.delivererId,
            order_request: this.orderRequest,
          }, { headers }).then(response => {
            console.log('pop-orders: ', response)
            this.loading = false
            this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `Ok`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.$router.push({name: 'pop-order-list'})
          }).catch( err => {
            this.loading = false
            console.log(err)
          })

        }
      })

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
